<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list">
            <div class="address" v-for="(item, index) in addressList" :key="index" @click="(addressIndex = index), (addressEdit = item)" :class="{ active: addressIndex == index }">
                <div class="name">{{ item.consignee }} {{ item.phoneNumber }}</div>
                <div class="phone">{{ item.address2 }}</div>
                <div class="status" v-if="item.isDefault">默认地址</div>
                <div class="edit" v-if="index === addressIndex" @click="(addressEdit = item), (ruleVisible = true), (status = 1)">修改</div>
            </div>
            <div class="add" @click="(ruleVisible = true), (addressEdit = { consignee: '', phoneNumber: '', remark: [], address: '', isDefault: false }), (status = 2)">
                <i class="el-icon-plus"></i>
                <div class="text">添加地址</div>
            </div>
        </div>
        <div class="titles">商品信息</div>
        <div class="item">
            <img :src="goodDetail.picUrl" alt="" />
            <div class="right">
                <div class="title">{{ goodDetail.name }}</div>
                <div class="bottom">
                    <div class="jifen">{{ goodDetail.exchangeScore }}积分</div>
                    <div class="numbers">X{{ goodDetail.num }}</div>
                </div>
            </div>
        </div>
        <div class="butt">
            <div class="jifenCount">
                总计：
                <span>{{ goodDetail.num * goodDetail.exchangeScore }}积分</span>
            </div>
            <div class="btn" @click="addOrder">立即兑换</div>
        </div>
        <!--创建社群-->
        <el-dialog :title="status == 1 ? '修改地址' : '添加地址'" :visible.sync="ruleVisible" width="40%" class="dialog" append-to-body>
            <div class="items">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">收货人：</span>
                </div>
                <el-input v-model="addressEdit.consignee" placeholder="请输入姓名"></el-input>
            </div>
            <div class="items">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">手机号码：</span>
                </div>
                <el-input v-model="addressEdit.phoneNumber" placeholder="请输入联系电话"></el-input>
            </div>
            <div class="items">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">所在地区：</span>
                </div>
                <el-cascader v-model="addressEdit.remark" :props="{ value: 'name', label: 'name' }" :options="city"></el-cascader>
            </div>
            <div class="items">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title">详细地址：</span>
                </div>
                <el-input placeholder="小区楼栋/乡村名称" type="textarea" v-model="addressEdit.address"></el-input>
            </div>
            <div class="items setDefault">
                <div>
                    <img src="@/assets/images/Frame652.png" alt="" />
                    <span class="title" @click="addressEdit.isDefault = !addressEdit.isDefault">设为默认地址：</span>
                </div>
                <el-switch v-model="addressEdit.isDefault" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ruleVisible = false">取 消</el-button>
                <el-button type="primary" @click="setAddress()">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="jifenVisible" width="25%" class="dialog" append-to-body>
            <div class="tpBox">
                <img class="tipImg" src="@/assets/images/ExclamationCircle.png" alt="" />
                <div class="tips">
                    <span class="tip">
                        您的积分余额为
                        <span class="red">105</span>
                    </span>
                    <span>您的积分太少了，无可兑换商品，快 去参加活动赚积分吧！</span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="jifenVisible = false">取 消</el-button>
                <el-button type="primary" @click="jifenVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getAddressList, setAddressSet, setAddress, addAddress, addOrder } from '@/api';
import { cityData } from '@/utils/city';
export default {
    name: 'OrderDetail',
    data() {
        return {
            addressIndex: null,
            //收获地址
            addressEdit: {
                consignee: '',
                phoneNumber: '',
                remark: [],
                address: '',
                isDefault: false,
            },
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '文化银行',
                    to: { path: '/bank' },
                },
                {
                    title: '确认订单',
                },
            ],
            ruleVisible: false,
            from: {
                regionList: [],
                region: [],
                address: '',
                setDefault: true,
            },
            jifenVisible: false,
            goodDetail: {},
            addressList: [],
            city: cityData,
            addressInfo: '', //  详细地址
            addressCity: {},
            status: null,
        };
    },
    watch: {
        addressCity(val) {
            console.log(val);
        },
    },
    created() {
        this.goodDetail = JSON.parse(this.$route.query.goodsDetails);
        this.getAddressList();
        console.log(this.city);
    },
    methods: {
        getAddressList() {
            getAddressList({ pageSize: 1000, userId: JSON.parse(localStorage.getItem('userInfo')).userId }).then((res) => {
                if (res.code === 200) {
                    res.rows.forEach((item, index) => {
                        console.log(item);
                        item.remark = JSON.parse(item.remark);
                        item.address2 = item.remark.join('') + item.address;
                        console.table(item);
                        if (item.isDefault) {
                            item.isDefault = true;
                            this.addressEdit = item;
                            this.addressIndex = index;
                        } else {
                            item.isDefault = false;
                            // this.addressIndex = null;
                        }
                    });
                    this.addressList = res.rows;
                }
            });
        },
        isDefault() {
            setAddressSet({ id: this.addressEdit.id, userId: localStorage.getItem('userInfo').userId || '' }).then((res) => {
                if (res.code === 200) {
                    this.$message.success('设置成功');
                    this.getAddressList();
                }
            });
        },
        setAddress() {
            if (!this.addressEdit.consignee) return this.$message.error('请输入收货人');
            if (!this.addressEdit.phoneNumber) return this.$message.error('请输入手机号码');
            if (!this.addressEdit.remark[0] || !this.addressEdit.remark[1] || !this.addressEdit.remark[2]) return this.$message.error('请选择所在地区');
            if (!this.addressEdit.address) return this.$message.error('请输入详细地址');
            if (this.addressEdit.address.length < 5) return this.$message.error('详细地址不能少于5个字');

            // console.log(this.addressCity);
            this.addressEdit.remark = JSON.stringify(this.addressEdit.remark);
            // console.log(this.addressEdit);
            this.addressEdit.userId = JSON.parse(localStorage.getItem('userInfo')).userId;
            this.addressEdit.isDefault = this.addressEdit.isDefault ? 1 : 0;
            if (this.status == 1) {
                setAddress(this.addressEdit).then((res) => {
                    if (res.code === 200) {
                        this.$message.success('修改成功');
                        this.getAddressList();
                        this.ruleVisible = false;
                    }
                });
            } else {
                // this.addressEdit.userId = localStorage.getItem('userInfo').userId;
                addAddress(this.addressEdit).then((res) => {
                    if (res.code === 200) {
                        this.$message.success('添加成功');
                        this.getAddressList();
                        this.ruleVisible = false;
                    }
                });
            }
            this.ruleVisible = false;
        },
        addOrder() {
            console.log(this.addressEdit);
            if (this.addressEdit.id) {
                addOrder({
                    deliveryAddressId: this.addressEdit.id,
                    goodsId: this.goodDetail.id,
                    quantity: this.goodDetail.num,
                    score: this.goodDetail.exchangeScore,
                    userId: JSON.parse(localStorage.getItem('userInfo')).userId,
                }).then((res) => {
                    if (res.code === 200) {
                        this.$message.success('下单成功');
                        setTimeout(() => {
                            this.$router.push({ path: '/my', query: { tabs: 3 } });
                        }, 1000);
                    }
                });
            } else {
                this.$message.error('请选择收货地址');
            }
        },
    },
};
</script>

<style scoped lang="less">
.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    padding: 24px 0;

    .address {
        background: white;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px;
        height: 140px;
        position: relative;

        .status {
            position: absolute;
            top: -4px;
            right: -4px;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
            padding: 4px 12px;
            background: #eda200;
            border-radius: 0 0 0 4px;
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        .edit {
            font-size: 14px;
            line-height: 20px;
            color: #eda200;
            margin-top: 10px;
            cursor: pointer;
        }

        .name {
            font-weight: 500;
            font-size: 17px;
            line-height: 25px;
            color: rgba(0, 0, 0, 0.8);
        }

        .phone {
            margin-top: 2px;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    .active {
        border: 4px dashed #eda200;
    }

    .add {
        cursor: pointer;
        background: white;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px;
        height: 140px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border-radius: 4px;

        i {
            font-size: 33px;
            font-weight: 700;
            color: #e5e5e5;
        }

        .text {
            margin-top: 16px;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.5);
        }
    }
}

.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    padding: 0 0 32px 0;
}

.item {
    display: flex;
    padding: 24px;
    background: #fff;
    border: 1px solid #ebebeb;

    img {
        width: 120px;
        height: 120px;
        border-radius: 4px;
    }

    .right {
        flex: 1;
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            color: #333333;
            width: 100%;
            .ellipsisLine(2);
        }

        .bottom {
            .jifen {
                font-weight: 700;
                font-size: 17px;
                line-height: 25px;
                color: #eda200;
            }

            .numbers {
                font-size: 16px;
                line-height: 23px;
                color: #666666;
                margin-top: 12px;
            }
        }
    }
}

.butt {
    padding: 24px;
    font-size: 16px;
    background: #f6f6f6;
    margin-bottom: 90px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    .btn {
        width: 180px;
        height: 44px;
        color: #fff;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        margin-top: 24px;
        background: #eda200;
        box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
        border-radius: 4px;
    }
}

.jifenCount {
    text-align: right;
    font-weight: 700;
    line-height: 23px;
    color: #333333;

    span {
        color: #eda200;
    }
}

.dialog {
    padding-top: 8px;

    .items {
        display: flex;
        flex-direction: column;

        ::v-deep .el-input__inner {
            border-radius: 4px;
        }

        div {
            margin-bottom: 12px;

            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                flex: none;
                order: 0;
                flex-grow: 0;
            }

            img {
                width: 7px;
                height: 17px;
            }
        }

        .jifen {
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: #fd8f30;
        }
    }

    .setDefault {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    .tpBox {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 12px;
        .tipImg {
            width: 22px;
            height: 22px;
            margin-right: 16px;
            margin-top: 2px;
        }

        .tips {
            display: flex;
            flex-direction: column;
            .tip {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #000000;
                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;
                .red {
                    color: #f33f3e;
                }
            }
            span:last-child {
                font-size: 16px;
                line-height: 24px;
                color: rgba(0, 0, 0, 0.5);
                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                margin-top: 8px;
            }
        }
    }
}

::v-deep .el-dialog__body {
    padding: 24px 24px 12px 24px;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
}
</style>
